@import "bulma";
@import "extensions/all";

// Overrides

.main-section {
  margin-bottom: 20px;
  
  @include from($tablet) {
    margin-bottom: 2rem;
  }
  
  &:last-child {
    margin-bottom: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.cards {
  padding: 30px 15px;

  @include from($tablet) {
    padding: $section-padding;
  }
}

// .hero + .cards {
//   @include from($desktop) {
//     margin-top: -180px;
//     padding-top: 0;
//     padding-right: 0;
//     padding-left: 0;
//   }
// }

.hero {
  &.is-primary {
    &.is-bold {
      &.is-overriden {
        background-image: linear-gradient(to bottom, #BA12F2 0%,#8213A7 100%);
      }
    }
  }
}

.navbar-main {
  @include until($desktop) {
    .navbar-menu {
      padding-right: 15px;
      padding-left: 15px;

      .navbar {
        &-item,
        &-link {
          font-size: $size-4;
          border-bottom: 2px solid $athens-gray;
          padding: 15px 0;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
