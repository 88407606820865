$footer-background-color: $white !default;
$footer-padding: 0 !default;

.footer {
  background-color: $footer-background-color;
  padding: $footer-padding;
  
  @include until($desktop) {
    .navbar {
      padding: 10px 0;
    }

    .navbar-menu {
      display: flex;
    }
  }
}
