$alert-padding: 1.3889rem 1.3889rem;
$alert-padding-medium: 9rem 1.5rem;
$alert-padding-large: 18rem 1.5rem;
$alert-radius: $radius-large;

.alert {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: $alert-radius;

  @include from($tablet) {
    margin-bottom: 2rem;
    padding: $alert-padding;
  }

  &-body {
    position: relative;
    margin-right: 2em;

    @include from($tablet) {
      padding-left: 1.3889rem;

      &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 3px;
        height: 100%;
      }
    }
  }

  &-icon {
    margin-left: auto;
  }

  @each $name, $pair in $colors {    
    &.is-#{$name} {
      $color: nth($pair, 1);
      $color-invert: nth($pair, 2);
      
      color: $color;
      background-color: rgba($color, 0.15);
      
      .alert-body {
        @include from($tablet) {
          
          &:before {
            margin-right: 0.8334rem;
            background-color: $color;
          }
        }
      }
    }
  }
}
